import { DragEventHandler, useRef } from "react";
import ReactFlow, {
  addEdge,
  removeElements,
  updateEdge,
  Controls,
  Background,
  BackgroundVariant,
  ConnectionLineType,
  OnLoadFunc,
  OnLoadParams,
  Edge,
  Connection,
  Elements,
  OnEdgeUpdateFunc,
} from "react-flow-renderer/nocss";
import GradientEdge from "./GradientEdge";
import { useBoardContext } from "../contexts/BoardContext";
import AbstractNode from "./AbstractNode";
import { getStepDefinitions } from "../stepDefinitions";

const defaultEdge = "default";
const edgeTypes = {
  [defaultEdge]: GradientEdge,
};

const nodeTypes = getStepDefinitions().reduce(
  (types, step) => ({ ...types, [step.id]: step.component || AbstractNode }),
  {}
);

function Board() {
  const reactFlowWrapper = useRef<HTMLDivElement>(null!);
  const {
    reactFlowInstance,
    setReactFlowInstance,
    elements,
    setElements,
    addStep,
  } = useBoardContext()!;

  const onConnect: (connection: Edge | Connection) => void = (params) =>
    setElements((els) =>
      addEdge({ ...params, type: defaultEdge, animated: false }, els)
    );

  const onElementsRemove: (elements: Elements) => void = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  const onLoad: OnLoadFunc = (_reactFlowInstance: OnLoadParams) =>
    setReactFlowInstance(_reactFlowInstance);

  const onDragOver: DragEventHandler = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop: DragEventHandler = (event) => {
    event.preventDefault();
    if (!reactFlowInstance) {
      return;
    }

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    addStep({
      type,
      position,
      data: {},
    });
  };

  const onEdgeUpdate: OnEdgeUpdateFunc = (oldEdge, newConnection) =>
    setElements((els) => updateEdge(oldEdge, newConnection, els));

  return (
    <div className="w-full h-full shadow-inset" ref={reactFlowWrapper}>
      <ReactFlow
        elements={elements}
        onConnect={onConnect}
        onElementsRemove={onElementsRemove}
        onLoad={onLoad}
        onDrop={onDrop}
        onDragOver={onDragOver}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        snapToGrid={true}
        onEdgeUpdate={onEdgeUpdate}
        connectionLineType={ConnectionLineType.SmoothStep}
      >
        <div className="absolute inset-0 opacity-40 bg-gradient-to-br from-emerald-50 via-blue-50 to-indigo-50"></div>
        <Background variant={BackgroundVariant.Dots} color="#ababab" />
        <Controls />
      </ReactFlow>
    </div>
  );
}

export default Board;
