import classNames from "classnames";
import { getGroupedStepDefinitions } from "../../stepDefinitions";
import { DragEvent } from "react";

function onDragStart(event: DragEvent, nodeType: string) {
  event.dataTransfer.setData("application/reactflow", nodeType);
  event.dataTransfer.effectAllowed = "move";
}

function getStepKindClassNames(kind: string) {
  switch (kind) {
    case "input":
      return "hover:border-emerald-400";

    case "output":
      return "hover:border-indigo-400";

    case "filter":
    default:
      return "hover:border-blue-400";
  }
}

function getPanelKindClassNames(kind: string) {
  switch (kind) {
    case "input":
      return "bg-emerald-50";

    case "output":
      return "bg-indigo-50";

    case "filter":
    default:
      return "bg-blue-50";
  }
}

const groups = getGroupedStepDefinitions();

function StepsList() {
  return (
    <div className="flex-1 min-h-0 overflow-y-auto scrollbar">
      <div className="px-1 space-y-1">
        {groups.map((group) => {
          const steps = group.steps;

          return (
            <div className="relative" key={group.id}>
              <div className="sticky top-0 z-20 flex items-center w-full pb-2 bg-white">
                <div
                  className={classNames(
                    "text-center w-full px-3 py-1.5 text-sm font-bold text-neutral-900 rounded-md mx-0.5",
                    getPanelKindClassNames(group.id)
                  )}
                >
                  {group.label}
                </div>
              </div>
              <div className="py-2">
                {steps.map((definition) => (
                  <div
                    data-type={definition.id}
                    key={definition.id}
                    onDragStart={(event) => onDragStart(event, definition.id)}
                    draggable
                    className={classNames(
                      getStepKindClassNames(group.id),
                      "hover:shadow-sm overflow-clip border-transparent group cursor-grab w-full rounded-md bg-white text-neutral-900 px-1 py-2 font-bold flex items-center space-x-2 border-2 hover:border-opacity-40 scale-98 hover:scale-100 hover:-translate-y-0.5 transition duration-300"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="flex-shrink-0 w-5 h-5 transition-colors duration-300 text-neutral-300 group-hover:text-neutral-400"
                      viewBox="0 0 24 24"
                    >
                      <rect height="3" width="3" y="2" x="8" />
                      <rect height="3" width="3" y="10" x="8" />
                      <rect height="3" width="3" y="18" x="8" />
                      <rect height="3" width="3" y="2" x="14" />
                      <rect height="3" width="3" y="10" x="14" />
                      <rect height="3" width="3" y="18" x="14" />
                    </svg>
                    <div>
                      <div className="flex justify-between flex-1 space-x-2">
                        <div className="font-bold truncate text-neutral-900">
                          {definition.label}
                        </div>
                      </div>

                      {definition.description ? (
                        <div className="text-sm font-light text-neutral-700">
                          {definition.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default StepsList;
