import { isNode, isEdge, Edge, Elements, Node, ElementId } from "react-flow-renderer/nocss";
import { FlowElement } from 'react-flow-renderer/dist/nocss';

export type NodeEdge = {
    id: string;
    target: Node;
    targetHandle?: ElementId | null;
    source: Node;
    sourceHandle?: ElementId | null;
}

export function getEdges(node: FlowElement, elements: Elements) : NodeEdge[] {
    if (!isNode(node)) {
        return [];
    }

    return elements
        .filter((e) => isEdge(e) && e.target === node.id)
        .map((e) => e as Edge)
        .map((e) => ({
            id: e.id,
            target: elements.find((el) => el.id === e.target) as Node,
            targetHandle: e.targetHandle,
            source: elements.find((el) => el.id === e.source) as Node,
            sourceHandle: e.sourceHandle,
        }));
}

export function reduceToTree(edges: NodeEdge[], elements: Elements) {
    const treeReducer = (previousValue: Tree[], currentValue: NodeEdge) : Tree[] => {
        const innerEdges = getEdges(currentValue.source, elements);
        return [
            ...previousValue,
            {
                edge: currentValue,
                incomingEdges: innerEdges.reduce(treeReducer, []),
            },
        ];
    };

    return edges.reduce(treeReducer, [])[0];
}

export type Tree = {
    edge: NodeEdge;
    incomingEdges: Tree[];
};

export function flattenAndReverseTree(tree: Tree) {
    const queue = [tree];
    const stack = [];

    // reverse and flatten tree
    // https://www.techiedelight.com/reverse-level-order-traversal-binary-tree/
    while (queue.length) {
        const currentEdge = queue.shift();

        const { incomingEdges, edge } = currentEdge!;
        stack.unshift(edge);

        if (incomingEdges.length) {
            queue.push(...incomingEdges);
        }
    }

    return stack;
}
