import { memo } from "react";
import { NodeProps } from "react-flow-renderer/dist/nocss";
import { nodeDataIsUnchanged } from "../../utils/render";
import { StepDefinition } from "../../stepDefinitions";
import AbstractNode from "../AbstractNode";
import { usePipelineOutput } from "../../hooks/usePipelineOutput";

const InlinePlainTextOutput = memo((props: NodeProps) => {
  const { output } = usePipelineOutput(props.id);

  return (
    <AbstractNode {...props}>
      <div className="my-4 nodrag nowheel">
        <input
          className="input input--output input--inline"
          type="text"
          placeholder="Output"
          readOnly
          value={output?.result || ""}
          onFocus={({ target }) => target.select()}
        />
      </div>
    </AbstractNode>
  );
}, nodeDataIsUnchanged);

export default InlinePlainTextOutput;

export const definition: StepDefinition = {
  label: "Inline Plain Text Output",
  description: "Pipes its input into a simple text field.",
  group: "output",

  component: InlinePlainTextOutput,

  pipe(value) {
    return value;
  },

  inputs: {
    text: {
      label: "Text",
      type: "String",
      required: true,
    },
  },
  outputs: {},
};
