import { memo } from "react";
import { NodeProps } from "react-flow-renderer/dist/nocss";
import { nanoid } from "nanoid";
import { StepDefinition } from "../../stepDefinitions";
import RefreshButton from "../ui/RefreshButton";
import AbstractNode from "../AbstractNode";

const RandomNanoidInput = memo(({ id, ...props }: NodeProps) => {
  return (
    <AbstractNode id={id} buttons={<RefreshButton id={id} />} {...props} />
  );
});

export default RandomNanoidInput;

type RandomNanoidInputData = { value: string };

export const definition: StepDefinition<RandomNanoidInputData> = {
  label: "Random nanoid",
  description: "Creates a random nanoid.",
  group: "input",

  component: RandomNanoidInput,

  init(data) {
    data.value = nanoid();
  },

  refresh(data) {
    data.value = nanoid();
  },

  pipe(_, { value }) {
    return value;
  },

  inputs: {},
  outputs: {
    randomNanoid: {
      label: "Random nanoid",
      type: "String",
    },
  },
};
