import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { getStepDefinition } from "../stepDefinitions";
import { nanoid } from "nanoid";
import {
  ElementId,
  FlowElement,
  Node,
  OnLoadParams,
  XYPosition,
} from "react-flow-renderer/dist/nocss";
import { isNode } from "react-flow-renderer/nocss";

interface BoardContextInterface {
  reactFlowInstance: OnLoadParams | null;
  setReactFlowInstance: Dispatch<SetStateAction<OnLoadParams | null>>;
  fitView: () => void;
  elements: FlowElement[];
  setElements: Dispatch<SetStateAction<FlowElement[]>>;
  refreshStep: (id: ElementId) => void;
  loadBoard: (board: Board) => void;
  addStep: (step: StepMetadata) => void;
  updateElementData: (id: string, data: object) => void;
}

export const BoardContext = createContext<BoardContextInterface | null>(null);

export const useBoardContext = () =>
  useContext<BoardContextInterface | null>(BoardContext)!;

const getId = () => nanoid();

type Board = {
  elements: FlowElement[];
};

type StepMetadata = { type: string; position: XYPosition; data: object };

function BoardContextProvider({
  children,
  initialBoard,
}: {
  children: ReactNode;
  initialBoard: Board;
}) {
  const [reactFlowInstance, setReactFlowInstance] =
    useState<OnLoadParams | null>(null);

  const initializeBoard = useCallback((board: Board) => {
    board.elements = board.elements || [];

    board.elements.forEach((step) => {
      if (!isNode(step)) {
        return;
      }

      const definition = getStepDefinition(step.type);
      if (!definition) {
        return;
      }

      step.data = step.data || {};

      definition.init && definition.init(step.data);
    });

    return board;
  }, []);

  const [elements, setElements] = useState(
    () => initializeBoard(initialBoard).elements
  );

  const fitView = useCallback(() => {
    reactFlowInstance?.fitView();
  }, [reactFlowInstance]);

  const loadBoard = useCallback(
    (board: Board) => {
      setElements(initializeBoard(board).elements);
    },
    [setElements, initializeBoard]
  );

  const addStep = useCallback(
    (step: StepMetadata) => {
      const definition = getStepDefinition(step.type);
      if (!definition) {
        return;
      }

      const newStep: Node = {
        id: getId(),
        ...step,
        data: step.data || {},
      };

      definition.init && definition.init(step.data);

      setElements((es) => es.concat(newStep));
    },
    [setElements]
  );

  const updateElementData = useCallback(
    (id, data) => {
      setElements((els) =>
        els.map((el) => {
          if (el.id === id) {
            const newData = {
              ...el.data,
              ...data,
            };

            const definition = getStepDefinition(el.type)!;
            definition.refresh && definition.refresh(newData);
            el.data = newData;
          }

          return el;
        })
      );
    },
    [setElements]
  );

  const refreshStep = useCallback(
    (id: ElementId) => {
      updateElementData(id, elements.find((el) => el.id === id)?.data);
    },
    [updateElementData, elements]
  );

  return (
    <BoardContext.Provider
      value={{
        reactFlowInstance,
        setReactFlowInstance,
        fitView,
        elements,
        setElements,
        refreshStep,
        loadBoard,
        addStep,
        updateElementData,
      }}
    >
      {children}
    </BoardContext.Provider>
  );
}

export default BoardContextProvider;
