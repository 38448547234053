import { memo, useState } from "react";
import { NodeProps } from "react-flow-renderer/dist/nocss";
import { useBoardContext } from "../../contexts/BoardContext";
import { StepDefinition } from "../../stepDefinitions";
import AbstractNode from "../AbstractNode";

type InlinePlainTextInputData = { value: string };

const InlinePlainTextInput = memo((props: NodeProps) => {
  const [inputValue, setInputValue] = useState(props.data.value);
  const { updateElementData } = useBoardContext();

  function setValue() {
    updateElementData(props.id, { value: inputValue });
  }

  return (
    <AbstractNode {...props}>
      <div className="my-4 nodrag nowheel">
        <input
          className="input input--input input--inline"
          type="text"
          placeholder="Input"
          value={inputValue}
          onChange={({ target }) => setInputValue(target.value)}
          onBlur={setValue}
        />
      </div>
    </AbstractNode>
  );
});

export default InlinePlainTextInput;

export const definition : StepDefinition<InlinePlainTextInputData> = {
  label: "Inline Plain Text Input",
  description: "Takes its input from a simple text field.",
  group: "input",

  component: InlinePlainTextInput,

  init(data) {
    data.value = data.value || "";
  },

  pipe(_, { value }) {
    return value;
  },

  inputs: {},
  outputs: {
    text: {
      label: "Text",
      type: "String",
    },
  },
};
