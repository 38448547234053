import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../components/ui/Modal";
import { useLibrary } from "../hooks/useLibrary";

function Library() {
  const [isModalOpened, setModalOpened] = useState(true);

  const { getLibrary, loadLibraryEntryIntoBoard } = useLibrary();
  const navigate = useNavigate();

  useEffect(() => {
    setModalOpened(true);

    return () => {
      closeModal();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    setModalOpened(false);
    setTimeout(() => navigate("/"), 300);
  };

  return (
    <Modal
      title="Library"
      opened={isModalOpened}
      setOpened={() => {
        closeModal();
      }}
    >
      <div className="grid grid-cols-3 gap-2">
        {getLibrary().map((entry) => (
          <button
            key={entry.id}
            onClick={() =>
              loadLibraryEntryIntoBoard(entry.id).then(() => closeModal())
            }
            className="flex items-center w-full px-3 py-2 space-x-2 font-bold text-left transition duration-300 scale-95 bg-white border-2 rounded-md hover:border-indigo-400 group border-neutral-100 text-neutral-900 hover:border-opacity-50 hover:shadow-sm hover:scale-100"
          >
            <div>
              <div className="flex justify-between flex-1 space-x-2">
                <div className="font-bold truncate text-neutral-900">
                  {entry.label}
                </div>
              </div>
              <div className="text-sm font-light text-neutral-700">
                {entry.description}
              </div>
            </div>
          </button>
        ))}
      </div>
    </Modal>
  );
}

export default Library;
