import { flattenAndReverseTree, NodeEdge, Tree } from "./tree";
import {
    getStepDefinition,
    getInputs,
    WithId,
    InputDefinition,
    ParameterValues,
} from "../stepDefinitions";

export type Output = {
    steps: ParameterValues;
    result?: string;
};

function resolveInputEdges(
    nodeId: string,
    stack: NodeEdge[]
): (input: InputDefinition & WithId) => {
    edges: NodeEdge[];
    input: InputDefinition & WithId;
} {
    return (input: InputDefinition & WithId) => ({
        edges: stack.filter(
            (edge) =>
                edge &&
                edge.targetHandle === input.id &&
                edge.target.id === nodeId
        ),
        input: input,
    });
}

function resolveInputValues(values: ParameterValues) : (
    params: ParameterValues,
    param: { edges: NodeEdge[]; input: InputDefinition }
) => ParameterValues {
    return function (
        params: ParameterValues,
        param: { edges: NodeEdge[]; input: InputDefinition }
    ) {
        if (!param) {
            return params;
        }

        const targetInput = param.edges[0]?.targetHandle;
        if (!targetInput) {
            return params;
        }

        const inputValues = param.edges.reduce(
            (previousValue: object[], value: NodeEdge) => {
                return [...previousValue, values[value.source.id]];
            },
            []
        );

        return {
            ...params,
            [targetInput]: param.input.acceptMultiple
                ? inputValues
                : inputValues[0],
        };
    };
}


export function resolveOutputValue(tree: Tree): Output | null {
    if (!tree) {
        return null;
    }

    const values: ParameterValues = {};

    flattenAndReverseTree(tree).forEach((currentEdge, _, stack) => {
        const { id, type, data } = currentEdge.source;
        const { pipe, ...definition } = getStepDefinition(type)!;

        const inputs = getInputs(definition)
            .map(resolveInputEdges(id, stack))
            .filter((edge) => !!edge?.edges)
            .reduce(resolveInputValues(values), {});

        values[id] = pipe(inputs, data);
    });

    const result = values[Object.keys(values).pop()!];

    return {
        steps: values,
        result: typeof result === "string" ? result : JSON.stringify(result),
    };
}
