import { memo } from "react";
import { NodeProps } from "react-flow-renderer/dist/nocss";
import { v4 } from "uuid";
import { StepDefinition } from "../../stepDefinitions";
import AbstractNode from "../AbstractNode";
import RefreshButton from "../ui/RefreshButton";

const RandomUuidInput = memo(({ id, ...props }: NodeProps) => {
  return (
    <AbstractNode id={id} buttons={<RefreshButton id={id} />} {...props} />
  );
});

export default RandomUuidInput;

type RandomUuidInputData = { value: string };

export const definition: StepDefinition<RandomUuidInputData> = {
  label: "Random UUID",
  description: "Creates a random UUID v4.",
  group: "input",

  component: RandomUuidInput,

  init(data) {
    data.value = v4();
  },

  refresh(data) {
    data.value = v4();
  },

  pipe(_, { value }) {
    return value;
  },

  inputs: {},
  outputs: {
    randomUuid: {
      label: "Random UUID",
      type: "String",
    },
  },
};
