import { useEffect, useState } from "react";
import { FlowElement } from "react-flow-renderer/nocss";
import { useBoardContext } from "../contexts/BoardContext";
import { Output, resolveOutputValue } from "../utils/output";
import { getEdges, reduceToTree } from "../utils/tree";

type PipelineHook = {
  output: Output | null;
};

export function usePipelineOutput(id: string): PipelineHook {
  const { elements } = useBoardContext();
  const [output, setOutput] = useState<Output | null>(null);

  useEffect(() => {
    const outputNode = { id } as any as FlowElement;
    const outputNodeEdges = getEdges(outputNode, elements);
    const tree = reduceToTree(outputNodeEdges, elements);
    setOutput(resolveOutputValue(tree));
  }, [id, elements]);

  return { output };
}
