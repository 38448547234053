import {
  getSmoothStepPath,
  getMarkerEnd,
  EdgeProps,
} from "react-flow-renderer/nocss";

function GradientEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  arrowHeadType,
  markerEndId,
}: EdgeProps) {
  const edgePath: string = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const markerEnd: string = getMarkerEnd(arrowHeadType, markerEndId);

  return (
    <>
      <defs>
        <linearGradient id="edge-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#818cf8" />
          <stop offset="100%" stopColor="#34d399" />
        </linearGradient>
      </defs>

      <path
        id={id}
        style={style}
        stroke="url(#edge-gradient)"
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
    </>
  );
}

export default GradientEdge;
