import { memo, useEffect, useState } from "react";
import { NodeProps } from "react-flow-renderer/dist/nocss";
import { useBoardContext } from "../../contexts/BoardContext";
import { StepDefinition } from "../../stepDefinitions";
import AbstractNode from "../AbstractNode";
import SegmentedControl from "../ui/SegmentedControl";

type UrlMode = "encode" | "decode";
type UrlFilterData = { mode: UrlMode };
type UrlFilterInputs = { rawValue: string };

const UrlFilter = memo((props: NodeProps<UrlFilterData>) => {
  const [mode, setMode] = useState<UrlMode>(props.data.mode);
  const { updateElementData } = useBoardContext();

  useEffect(() => {
    updateElementData(props.id, { mode });
  }, [updateElementData, props.id, mode]);

  return (
    <AbstractNode
      buttons={
        <SegmentedControl
          options={[
            { label: "Encode", value: "encode" },
            { label: "Decode", value: "decode" },
          ]}
          activeOption={mode}
          setActiveOption={setMode as (value: string) => void}
        />
      }
      {...props}
    />
  );
});

export default UrlFilter;

export const definition: StepDefinition<UrlFilterData, UrlFilterInputs> =
  {
    label: "URL",
    description: "Encodes or decodes the given input to/from URL format.",
    group: "filter",

    component: UrlFilter,

    init(data) {
      data.mode =
        data.mode === "encode" || data.mode === "decode" ? data.mode : "encode";
    },

    pipe({ rawValue }, { mode }) {
      try {
        return (
          rawValue &&
          (mode === "decode"
            ? decodeURIComponent(rawValue)
            : encodeURIComponent(rawValue)
          )
        );
      } catch {}
    },

    inputs: {
      rawValue: {
        label: "Raw Value",
        type: "String",
        required: true,
        acceptMultiple: false,
      },
    },
    outputs: {
      processedValue: {
        label: "Processed Value",
        type: "String",
      },
    },
  };
