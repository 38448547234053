import { memo, useEffect, useState } from "react";
import { NodeProps } from "react-flow-renderer/dist/nocss";
import Utf8 from "crypto-js/enc-utf8";
import Base64 from "crypto-js/enc-base64";
// import classNames from "classnames";
import { useBoardContext } from "../../contexts/BoardContext";
import { StepDefinition } from "../../stepDefinitions";
// import Tag from "../ui/Tag";
import AbstractNode from "../AbstractNode";
import SegmentedControl from "../ui/SegmentedControl";

type Base64Mode = "encode" | "decode";
type Base64FilterData = { mode: Base64Mode };
type Base64FilterInputs = { rawValue: string };

const Base64Filter = memo((props: NodeProps<Base64FilterData>) => {
  const [mode, setMode] = useState<Base64Mode>(props.data.mode);
  const { updateElementData } = useBoardContext();

  useEffect(() => {
    updateElementData(props.id, { mode });
  }, [updateElementData, props.id, mode]);

  // function toggleMode() {
  //   setMode((prevMode) => (prevMode === "decode" ? "encode" : "decode"));
  // }

  return (
    <AbstractNode
      buttons={
        <SegmentedControl
          options={[
            { label: "Encode", value: "encode" },
            { label: "Decode", value: "decode" },
          ]}
          activeOption={mode}
          setActiveOption={setMode as (value: string) => void}
        />
      }
      {...props}
    />
  );
});

export default Base64Filter;

export const definition: StepDefinition<Base64FilterData, Base64FilterInputs> =
  {
    label: "Base64",
    description: "Encodes or decodes the given input to/from base64.",
    group: "filter",

    component: Base64Filter,

    init(data) {
      data.mode =
        data.mode === "encode" || data.mode === "decode" ? data.mode : "encode";
    },

    pipe({ rawValue }, { mode }) {
      try {
        return (
          rawValue &&
          (mode === "decode"
            ? Base64.parse(rawValue).toString(Utf8)
            : Base64.stringify(Utf8.parse(rawValue)))
        );
      } catch {}
    },

    inputs: {
      rawValue: {
        label: "Raw Value",
        type: "String",
        required: true,
        acceptMultiple: false,
      },
    },
    outputs: {
      processedValue: {
        label: "Processed Value",
        type: "String",
      },
    },
  };
