import { memo, useRef, useState } from "react";
import { nodeDataIsUnchanged } from "../../utils/render";
import { NodeProps } from "react-flow-renderer/dist/nocss";
import { StepDefinition } from "../../stepDefinitions";
import Modal from "../ui/Modal";
import AbstractNode from "../AbstractNode";
import { usePipelineOutput } from "../../hooks/usePipelineOutput";

const PlainTextOutput = memo((props: NodeProps) => {
  const [isModalOpened, setModalOpened] = useState(false);

  const { output } = usePipelineOutput(props.id);

  const textareaRef = useRef(null);

  return (
    <>
      <Modal
        title={definition.label}
        opened={isModalOpened}
        setOpened={setModalOpened}
        initialFocus={textareaRef}
      >
        <label className="block">
          <span className="text-sm font-semibold text-neutral-900">Output</span>
          <textarea
            className="scrollbar input input--output leading-5 min-h-[160px] w-full"
            placeholder="Output"
            readOnly
            value={output?.result || ""}
            onFocus={({ target }) => target.select()}
            ref={textareaRef}
          />
        </label>
      </Modal>
      <AbstractNode
        buttons={
          <button
            onClick={() => setModalOpened(true)}
            className="p-1 transition-colors duration-300 rounded-full text-neutral-400 hover:text-neutral-600 hover:bg-neutral-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="w-4 h-4"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </button>
        }
        {...props}
      />
    </>
  );
}, nodeDataIsUnchanged);

export default PlainTextOutput;

export const definition: StepDefinition = {
  label: "Plain Text Output",
  description: "Pipes its input into a simple multi-line text field.",
  group: "output",

  component: PlainTextOutput,

  pipe(value) {
    return value;
  },

  inputs: {
    text: {
      label: "Text",
      type: "String",
      required: true,
    },
  },
  outputs: {},
};
