import { Dialog, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren, ReactNode } from "react";

type ModalProps = PropsWithChildren<
  {
    title?: string;
    buttons?: ReactNode;
    opened: boolean;
    setOpened: (value: boolean) => void;
    className?: string;
  } & {
    // Dialog Prop
    initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
  }
>;

function Modal({
  title,
  buttons,
  opened,
  setOpened,
  children,
  ...props
}: ModalProps) {
  return (
    <Transition.Root appear={opened} show={opened} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-y-auto nodrag nowheel"
        onClose={setOpened}
        {...props}
      >
        <div className="flex items-center justify-center h-screen p-8">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-neutral-50 bg-opacity-70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="block overflow-hidden transition-all transform bg-white shadow-card border-2 border-neutral-100 rounded-xl w-full h-full">
              {title ? (
                <div className="flex items-center justify-between px-4 py-2 mt-1">
                  <Dialog.Title as="div" className="font-bold text-neutral-900">
                    {title}
                  </Dialog.Title>

                  <button
                    aria-label="Close"
                    onClick={() => setOpened(false)}
                    className="focus:outline-none focus:ring focus:ring-indigo-400/60 text-neutral-700 hover:text-neutral-900 hover:bg-neutral-100 transition-colors duration-300 rounded-full p-1.5"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              ) : null}

              <div className="px-4 w-full h-[calc(100%-6.5rem)] overflow-scroll">
                {children}
              </div>

              {buttons ? (
                <div className="px-4 py-2 border-t-2 border-neutral-100 sm:flex sm:flex-row-reverse">
                  {buttons}
                </div>
              ) : null}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Modal;
