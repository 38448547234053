import { memo, useCallback, useState } from "react";
import { NodeProps } from "react-flow-renderer/dist/nocss";
import md5 from "crypto-js/md5";
import sha256 from "crypto-js/sha256";
import sha512 from "crypto-js/sha512";
import { useBoardContext } from "../../contexts/BoardContext";
import { nodeDataIsUnchanged } from "../../utils/render";
import { StepDefinition } from "../../stepDefinitions";
import SettingsPopup from "../ui/SettingsPopup";
import AbstractNode from "../AbstractNode";

type AlgoId = "md5" | "sha256" | "sha512";
type Algo = {
  key: AlgoId;
  label: string;
  hash: (input: string) => string;
};

type HashFilterData = { algo: AlgoId };
type HashFilterInputs = { rawValue: string };

const HashFilter = memo((props: NodeProps<HashFilterData>) => {
  const { updateElementData } = useBoardContext();
  const [algoValue, setAlgoValue] = useState(props.data.algo);

  const commit = useCallback(() => {
    updateElementData(props.id, { algo: algoValue });
  }, [updateElementData, props.id, algoValue]);

  return (
    <AbstractNode
      buttons={
        <SettingsPopup onSubmit={commit}>
          <div className="space-y-4">
            <label className="block">
              <span className="text-sm font-semibold text-neutral-900">
                Algorithm
              </span>

              <select
                className="input input--neutral"
                value={algoValue}
                onChange={({ target }) => setAlgoValue(target.value as AlgoId)}
              >
                {algos.map((algo) => (
                  <option key={algo.key} value={algo.key}>
                    {algo.label}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </SettingsPopup>
      }
      {...props}
    />
  );
}, nodeDataIsUnchanged);

const algos: Algo[] = [
  {
    key: "md5",
    label: "MD5",
    hash(input) {
      return md5(input).toString();
    },
  },
  {
    key: "sha256",
    label: "SHA256",
    hash(input) {
      return sha256(input).toString();
    },
  },
  {
    key: "sha512",
    label: "SHA512",
    hash(input) {
      return sha512(input).toString();
    },
  },
];

function hash(input: string, algo: AlgoId): string | undefined {
  return algos.find((a) => a.key === algo)?.hash(input);
}

export default HashFilter;

export const definition: StepDefinition<HashFilterData, HashFilterInputs> = {
  label: "Hash",
  description: "Computes the hash of its input.",
  group: "filter",

  component: HashFilter,

  pipe({ rawValue }, { algo }) {
    return rawValue && hash(rawValue, algo);
  },

  init(data) {
    data.algo =
      algos.findIndex((algo) => algo.key === data.algo) === -1
        ? algos[0].key
        : data.algo;
  },

  refresh(data) {},

  inputs: {
    rawValue: {
      label: "Raw Value",
      type: "String",
      required: true,
      acceptMultiple: false,
    },
  },
  outputs: {
    hashedValue: {
      label: "Hashed Value",
      type: "String",
    },
  },
};
