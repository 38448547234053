import { ReactFlowProvider } from "react-flow-renderer/nocss";
import Toolbar from "../components/toolbar/Toolbar";
import BoardContextProvider from "../contexts/BoardContext";
import Board from "../components/Board";
import Watermark from "../components/Watermark";
import { library } from "../hooks/useLibrary";
import { Allotment } from "allotment";
import { Outlet } from "react-router-dom";

const INITIAL_STATE = library[0].board;

function Root() {
  return (
    <BoardContextProvider initialBoard={INITIAL_STATE}>
      <ReactFlowProvider>
        <Watermark />


        <Allotment vertical={false} minSize={280} defaultSizes={[80, 20]}>
          <Board />
          <Toolbar />
        </Allotment>

        <Outlet />
      </ReactFlowProvider>
    </BoardContextProvider>
  );
}

export default Root;
