import { memo } from "react";
import { nodeDataIsUnchanged } from "../../utils/render";
import { StepDefinition } from "../../stepDefinitions";
import AbstractNode from "../AbstractNode";

type ReverseArrayFilterInputs = { array: string[] };

const ReverseArrayFilter = memo((props) => {
  return <AbstractNode {...props} />;
}, nodeDataIsUnchanged);

export default ReverseArrayFilter;

export const definition: StepDefinition<{}, ReverseArrayFilterInputs> = {
  label: "Reverse Array",
  description: "Reverses the input array.",
  group: "filter",

  component: ReverseArrayFilter,

  pipe({ array }) {
    return array && [...array].reverse();
  },

  inputs: {
    array: {
      label: "Input Array",
      type: "StringArray",
      required: true,
      acceptMultiple: false,
    },
  },
  outputs: {
    hashedValue: {
      label: "Reversed Array",
      type: "StringArray",
    },
  },
};
