import { memo, useCallback, useState } from "react";
import { NodeProps } from "react-flow-renderer/dist/nocss";
import { useBoardContext } from "../../contexts/BoardContext";
import { nodeDataIsUnchanged } from "../../utils/render";
import { StepDefinition } from "../../stepDefinitions";
import Tag from "../ui/Tag";
import SettingsPopup from "../ui/SettingsPopup";
import RefreshButton from "../ui/RefreshButton";
import AbstractNode from "../AbstractNode";

type RandomStringInputData = { chars: string; length: number; value: string };

const RandomStringInput = memo(({ id, ...props }: NodeProps) => {
  const { updateElementData } = useBoardContext();

  const [charsValue, setCharsValue] = useState(props.data.chars);
  const [lengthValue, setLengthValue] = useState(props.data.length);

  const commit = useCallback(() => {
    updateElementData(id, { chars: charsValue, length: lengthValue });
  }, [updateElementData, id, charsValue, lengthValue]);

  return (
    <AbstractNode
      id={id}
      buttons={
        <>
          <RefreshButton id={id} />
          <SettingsPopup onSubmit={commit}>
            <div className="space-y-4">
              <label className="block">
                <span className="text-sm font-semibold text-neutral-900">
                  Length
                </span>

                <input
                  className="input input--neutral"
                  type="number"
                  value={lengthValue}
                  onChange={({ target }) =>
                    setLengthValue(parseInt(target.value))
                  }
                />
              </label>

              <label className="block">
                <span className="text-sm font-semibold text-neutral-900">
                  Chars
                </span>

                <input
                  className="input input--neutral"
                  type="text"
                  value={charsValue}
                  onChange={({ target }) => setCharsValue(target.value)}
                />

                <div className="flex flex-wrap gap-1 mt-2">
                  {presets.map((preset) => (
                    <Tag
                      as="button"
                      key={preset.key}
                      onClick={() => setCharsValue(preset.chars)}
                    >
                      {preset.label}
                    </Tag>
                  ))}
                </div>
              </label>
            </div>
          </SettingsPopup>
        </>
      }
      {...props}
    />
  );
}, nodeDataIsUnchanged);

const presets = [
  {
    key: "alphanumeric",
    label: "Alpha-Numeric",
    chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  },
  {
    key: "numeric",
    label: "Numeric",
    chars: "0123456789",
  },
  {
    key: "alpha",
    label: "Alpha",
    chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
  },
  {
    key: "password",
    label: "Password",
    chars:
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789&w`´/\\#%,.;:+-_?!*°=$§&()~|<>\"'{}[]@",
  },
];

const DEFAULT_LENGTH = 16;

function generateRandomString(length: number, characters: string) {
  let result = "";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export default RandomStringInput;

export const definition: StepDefinition<RandomStringInputData> = {
  label: "Random String",
  description: "Creates a random string based on the passed configuration.",
  group: "input",

  component: RandomStringInput,

  pipe(_, { value }) {
    return value;
  },

  init(data) {
    data.chars = data.chars || presets[0].chars;
    data.length = data.length || DEFAULT_LENGTH;

    data.value = generateRandomString(data.length, data.chars);
  },

  refresh(data) {
    data.value = generateRandomString(data.length, data.chars);
  },

  inputs: {},
  outputs: {
    randomString: {
      label: "Random String",
      type: "String",
    },
  },
};
