import { memo, useCallback, useState } from "react";
import { useBoardContext } from "../../contexts/BoardContext";
import { nodeDataIsUnchanged } from "../../utils/render";
import { StepDefinition } from "../../stepDefinitions";
import Tag from "../ui/Tag";
import SettingsPopup from "../ui/SettingsPopup";
import AbstractNode from "../AbstractNode";

type SplitFilterData = { separator: string };
type SplitFilterInputs = { text: string };
type SplitPreset = {
  key: string;
  label: string;
  char: string;
};

const SplitFilter = memo((props) => {
  const { updateElementData } = useBoardContext();
  const [separatorValue, setSeparatorValue] = useState(props.data.separator);

  const commit = useCallback(() => {
    updateElementData(props.id, { separator: separatorValue });
  }, [updateElementData, props.id, separatorValue]);

  return (
    <AbstractNode
      buttons={
        <SettingsPopup onSubmit={commit}>
          <div className="space-y-4">
            <label className="block">
              <span className="text-sm font-semibold text-neutral-900">
                Separator
              </span>

              <input
                className="input input--neutral"
                value={separatorValue}
                onChange={({ target }) => setSeparatorValue(target.value)}
              />

              <div className="flex flex-wrap gap-1 mt-2">
                {presets.map((preset) => (
                  <Tag
                    as="button"
                    key={preset.key}
                    onClick={() => setSeparatorValue(preset.char)}
                  >
                    {preset.label}
                  </Tag>
                ))}
              </div>
            </label>
          </div>
        </SettingsPopup>
      }
      {...props}
    />
  );
}, nodeDataIsUnchanged);

export default SplitFilter;

const presets: SplitPreset[] = [
  {
    key: "line",
    label: "Line Break",
    char: "\\n",
  },
  {
    key: "comma",
    label: "Comma",
    char: ",",
  },
  {
    key: "space",
    label: "Space",
    char: " ",
  },
  {
    key: "character",
    label: "Character",
    char: "",
  },
];

function replaceSpecialTokens(separator: string): string {
  return separator.replace("\\n", "\n").replace("\\t", "\t");
}

export const definition: StepDefinition<SplitFilterData, SplitFilterInputs> = {
  label: "Split",
  description: "Splits the input into an array.",
  group: "filter",

  component: SplitFilter,

  pipe({ text }, { separator }) {
    return text && text.split(replaceSpecialTokens(separator));
  },

  init(data) {
    data.separator ??= ",";
  },

  refresh(data) {},

  inputs: {
    text: {
      label: "Text",
      type: "String",
      required: true,
      acceptMultiple: false,
    },
  },
  outputs: {
    hashedValue: {
      label: "Array",
      type: "StringArray",
    },
  },
};
