import classNames from "classnames";
import {
  cloneElement,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from "react";

type ButtonProps = PropsWithChildren<
  {
    className?: string;
    icon?: ReactElement;
    as?: keyof JSX.IntrinsicElements;
  } & HTMLAttributes<HTMLButtonElement>
>;

function Button({ className, as, icon, children, ...props }: ButtonProps) {
  return (
    <button
      className={classNames(
        className,
        "flex items-center space-x-2 px-3.5 py-1.5 group p-0.5 relative bg-neutral-100 hover:bg-neutral-200 hover:border-neutral-300 transition-colors duration-300 rounded-md"
      )}
      {...props}
    >
      {icon
        ? cloneElement(icon, {
            className: classNames(
              "w-6 h-6",
              icon.props.className
            ),
          })
        : null}

      <span className="text-sm font-bold transition-colors duration-300 text-neutral-900 group-hover:text-neutral-900">
        {children}
      </span>
    </button>
  );
}

export default Button;
