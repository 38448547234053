function Watermark() {
  return (
    <div className="absolute top-4 left-4 text-neutral-900 pointer-events-none select-none z-30">
      <div className="w-full flex items-center justify-center space-x-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
          />
        </svg>
        <span className="font-bold">cout.io</span>
      </div>
    </div>
  );
}

export default Watermark;
