import { memo, useCallback, useState } from "react";
import { useBoardContext } from "../../contexts/BoardContext";
import { nodeDataIsUnchanged } from "../../utils/render";
import { StepDefinition } from "../../stepDefinitions";
import SettingsPopup from "../ui/SettingsPopup";
import AbstractNode from "../AbstractNode";

type JoinInputsFilterData = { separator: string };
type JoinInputsFilterInputs = { inputValues: string[] };

const JoinInputsFilter = memo((props) => {
  const { updateElementData } = useBoardContext();
  const [separatorValue, setSeparatorValue] = useState(props.data.separator);

  const commit = useCallback(() => {
    updateElementData(props.id, { separator: separatorValue });
  }, [updateElementData, props.id, separatorValue]);

  return (
    <AbstractNode
      buttons={
        <SettingsPopup onSubmit={commit}>
          <div className="space-y-4">
            <label className="block">
              <span className="text-sm font-semibold text-neutral-900">
                Separator
              </span>

              <input
                className="input input--neutral"
                value={separatorValue}
                onChange={({ target }) => setSeparatorValue(target.value)}
              />
            </label>
          </div>
        </SettingsPopup>
      }
      {...props}
    />
  );
}, nodeDataIsUnchanged);

export default JoinInputsFilter;

export const definition: StepDefinition<
  JoinInputsFilterData,
  JoinInputsFilterInputs
> = {
  label: "Join Inputs",
  description:
    "Joins multiple inputs into one in the order they have been connected to the filter.",
  group: "filter",

  component: JoinInputsFilter,

  pipe({ inputValues }, { separator }) {
    return inputValues?.length && inputValues.reverse().join(separator);
  },

  init(data) {
    data.separator ??= ",";
  },

  refresh(data) {},

  inputs: {
    inputValues: {
      label: "Input Values",
      type: "String",
      required: true,
      acceptMultiple: true,
    },
  },
  outputs: {
    hashedValue: {
      label: "Joined Value",
      type: "String",
    },
  },
};
