import { useCallback } from "react";
import { Connection, Node, isNode } from "react-flow-renderer/nocss";
import { useBoardContext } from "../contexts/BoardContext";
import { getStepDefinition } from "../stepDefinitions";
import { getEdges } from "../utils/tree";

type ConnectionValidatorHook = {
  isValidConnection(connection: Connection): boolean;
};

export function useConnectionValidator(): ConnectionValidatorHook {
  const { elements } = useBoardContext();

  const isValidConnection = useCallback(
    (connection: Connection) => {
      const source = elements.find(
        (node) => node.id === connection.source && isNode(node)
      ) as Node;
      const target = elements.find(
        (node) => node.id === connection.target && isNode(node)
      ) as Node;

      if (!source || !target) {
        return false;
      }

      const sourceDefinition = getStepDefinition(source.type);
      const sourceOutput = sourceDefinition?.outputs[connection.sourceHandle!];

      const targetDefinition = getStepDefinition(target.type);
      const targetInput = targetDefinition?.inputs[connection.targetHandle!];

      const edges = getEdges(target, elements);
      const handleIsFull =
        edges.findIndex(
          (edge) => edge.targetHandle === connection.targetHandle
        ) !== -1;

      if (handleIsFull && !targetInput?.acceptMultiple) {
        return false;
      }

      return sourceOutput?.type === targetInput?.type;
    },
    [elements]
  );

  return {
    isValidConnection,
  };
}
