import { memo } from "react";
import { nodeDataIsUnchanged } from "../../utils/render";
import { StepDefinition } from "../../stepDefinitions";
import AbstractNode from "../AbstractNode";

type ShuffleArrayFilterInputs = { array: string[] };

const ShuffleArrayFilter = memo((props) => {
  return <AbstractNode {...props} />;
}, nodeDataIsUnchanged);

export default ShuffleArrayFilter;

export const definition: StepDefinition<{}, ShuffleArrayFilterInputs> = {
  label: "Shuffle Array",
  description: "Randomizes the input array.",
  group: "filter",

  component: ShuffleArrayFilter,

  pipe({ array }) {
    return array && array.sort(() => Math.random() - 0.5);
  },

  inputs: {
    array: {
      label: "Input Array",
      type: "StringArray",
      required: true,
      acceptMultiple: false,
    },
  },
  outputs: {
    hashedValue: {
      label: "Shuffled Array",
      type: "StringArray",
    },
  },
};
