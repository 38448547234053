import { memo, useEffect, useState } from "react";
import { NodeProps } from "react-flow-renderer/dist/nocss";
import { useBoardContext } from "../../contexts/BoardContext";
import { StepDefinition } from "../../stepDefinitions";
import AbstractNode from "../AbstractNode";
import SegmentedControl from "../ui/SegmentedControl";

type ChangeCaseMode = "lower" | "upper";
type ChangeCaseFilterData = { mode: ChangeCaseMode };
type ChangeCaseFilterInputs = { text: string };

const ChangeCaseFilter = memo((props: NodeProps<ChangeCaseFilterData>) => {
  const [mode, setMode] = useState<ChangeCaseMode>(props.data.mode);
  const { updateElementData } = useBoardContext();

  useEffect(() => {
    updateElementData(props.id, { mode });
  }, [updateElementData, props.id, mode]);

  return (
    <AbstractNode
      buttons={
        <SegmentedControl
          options={[
            { label: "Lower", value: "lower" },
            { label: "Upper", value: "upper" },
          ]}
          activeOption={mode}
          setActiveOption={setMode as (value: string) => void}
        />
      }
      {...props}
    />
  );
});

export default ChangeCaseFilter;

export const definition: StepDefinition<
  ChangeCaseFilterData,
  ChangeCaseFilterInputs
> = {
  label: "Change Case",
  description: "Converts the given input to upper/lower case.",
  group: "filter",

  component: ChangeCaseFilter,

  init(data) {
    data.mode =
      data.mode === "lower" || data.mode === "upper" ? data.mode : "lower";
  },

  pipe({ text }, { mode }) {
    try {
      return (
        text && (mode === "upper" ? text.toUpperCase() : text.toLowerCase())
      );
    } catch {}
  },

  inputs: {
    text: {
      label: "Text",
      type: "String",
      required: true,
      acceptMultiple: false,
    },
  },
  outputs: {
    processedText: {
      label: "Processed Text",
      type: "String",
    },
  },
};
