import classNames from "classnames";
import { PropsWithChildren, ReactNode, useMemo } from "react";
import { Node } from "react-flow-renderer";
import { Handle, NodeProps, Position } from "react-flow-renderer/nocss";
import { useBoardContext } from "../contexts/BoardContext";
import { useConnectionValidator } from "../hooks/useConnectionValidator";
import { getInputs, getOutputs, getStepDefinition } from "../stepDefinitions";
import { getEdges } from "../utils/tree";

type AbstractNodeProps<T = any> = PropsWithChildren<
  {
    buttons?: ReactNode;
  } & NodeProps<T>
>;

function AbstractNode({
  type,
  isConnectable,
  buttons,
  children,
  ...props
}: AbstractNodeProps) {
  const { isValidConnection } = useConnectionValidator();
  const { elements } = useBoardContext();

  const edges = useMemo(
    () => getEdges(props as any as Node, elements),
    [props, elements]
  );

  const definition = getStepDefinition(type);
  if (!definition) {
    return null;
  }

  const inputs = getInputs(definition);
  const outputs = getOutputs(definition);

  return (
    <div className={`bg-white w-[240px] rounded-md shadow-card p-2 node node--${definition.group}`}>
      <div className="flex flex-1 justify-between space-x-2 min-h-[32px] items-center">
        <div className="font-bold truncate text-neutral-900">
          {definition.label}
        </div>

        {buttons ? (
          <div className="flex items-center flex-shrink-0">{buttons}</div>
        ) : null}
      </div>

      {definition.description ? (
        <div className="text-sm font-normal text-neutral-600">
          {definition.description}
        </div>
      ) : null}

      {children}

      <div className="mt-5 space-y-3">
        {inputs?.length ? (
          <div>
            <div className="mb-1 text-sm font-bold text-center text-neutral-900">
              Inputs
            </div>
            <div className="space-y-1">
              {inputs.map((input) => (
                <div
                  className="relative flex items-center py-1 rounded-md bg-emerald-50"
                  key={input.id}
                >
                  <Handle
                    id={input.id}
                    className={classNames(
                      "border-emerald-400 border-opacity-90",
                      edges.findIndex((e) => e.targetHandle === input.id) === -1
                        ? " ring-offset-1 ring-2 ring-emerald-400 ring-opacity-40"
                        : ""
                    )}
                    type="target"
                    position={Position.Left}
                    onConnect={(params) =>
                      console.log("handle onConnect", params, input)
                    }
                    isConnectable={isConnectable}
                    isValidConnection={() => false}
                  />
                  <span className="px-3 text-xs font-semibold text-neutral-900">
                    {input.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {outputs?.length ? (
          <div>
            <div className="mb-1 text-sm font-bold text-center text-neutral-900">
              Outputs
            </div>
            <div className="space-y-1">
              {outputs.map((output) => (
                <div
                  className="relative flex items-center py-1 rounded-md bg-indigo-50"
                  key={output.id}
                >
                  <Handle
                    id={output.id}
                    className="border-indigo-400 border-opacity-90 ring-offset-1 ring-2 ring-indigo-400 ring-opacity-40"
                    type="source"
                    position={Position.Right}
                    onConnect={(params) =>
                      console.log("handle onConnect", params, output)
                    }
                    isConnectable={isConnectable}
                    isValidConnection={(e) => {
                      console.log({ e });
                      return isValidConnection(e);
                    }}
                  />
                  <span className="px-3 text-xs font-semibold text-neutral-900">
                    {output.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default AbstractNode;
