import { createRef, forwardRef, useMemo, useRef } from "react";

type Option = { label: string; value: string };
type Options = Option[];

const Segment = forwardRef<HTMLButtonElement, any>(
  ({ children, ...props }, ref) => (
    <button
      ref={ref}
      {...props}
      className="relative z-10 font-semibold flex items-center justify-center w-full px-1.5 py-0.5 overflow-hidden text-xs text-center transition-colors duration-100 rounded appearance-none cursor-pointer select-none focus:outline-none duration-0"
    >
      {children}
    </button>
  )
);

function getTrackStyles(el: HTMLElement | null) {
  if (!el) {
    return {};
  }

  return {
    width: el.offsetWidth,
    transform: `translateX(${el.offsetLeft}px)`,
  };
}

function SegmentedControl({
  options,
  activeOption,
  setActiveOption,
}: {
  options: Options;
  activeOption: string;
  setActiveOption: (value: string) => void;
}) {
  const elementsRef = useRef(options.map(() => createRef<HTMLButtonElement>()));

  const trackStyles = useMemo(() => {
    const activeRef =
      elementsRef.current[options.findIndex((x) => x.value === activeOption)];
    return getTrackStyles(activeRef?.current);
  }, [options, activeOption]);

  return (
    <div className="relative flex justify-center items-center overflow-hidden w-full rounded-md p-0.5 space-x-1 bg-neutral-100">
      {options.map((option, idx) => (
        <Segment
          key={option.value}
          onClick={() => setActiveOption(option.value)}
          ref={elementsRef.current[idx]}
        >
          {option.label}
        </Segment>
      ))}
      <span
        className="absolute shadow-sm left-0 top-0.5 bottom-0.5 !ml-0 pointer-events-none duration-200 rounded bg-white"
        style={trackStyles}
      ></span>
    </div>
  );
}

export default SegmentedControl;
