import { memo, useEffect, useState } from "react";
import { NodeProps } from "react-flow-renderer/dist/nocss";
import Utf8 from "crypto-js/enc-utf8";
import Hex from "crypto-js/enc-hex";
import { useBoardContext } from "../../contexts/BoardContext";
import { StepDefinition } from "../../stepDefinitions";
import AbstractNode from "../AbstractNode";
import SegmentedControl from '../ui/SegmentedControl';

type HexMode = "encode" | "decode";
type HexFilterData = { mode: HexMode };
type HexFilterInputs = { rawValue: string };

const HexFilter = memo((props: NodeProps<HexFilterData>) => {
  const [mode, setMode] = useState<HexMode>(props.data.mode);
  const { updateElementData } = useBoardContext();

  useEffect(() => {
    updateElementData(props.id, { mode });
  }, [updateElementData, props.id, mode]);

  return (
    <AbstractNode
      buttons={
        <SegmentedControl
          options={[
            { label: "Encode", value: "encode" },
            { label: "Decode", value: "decode" },
          ]}
          activeOption={mode}
          setActiveOption={setMode as (value: string) => void}
        />
      }
      {...props}
    />
  );
});

export default HexFilter;

export const definition: StepDefinition<HexFilterData, HexFilterInputs> = {
  label: "Hex",
  description: "Encodes or decodes the given input to/from HEX.",
  group: "filter",

  component: HexFilter,

  init(data) {
    data.mode =
      data.mode === "encode" || data.mode === "decode" ? data.mode : "encode";
  },

  pipe({ rawValue }, { mode }) {
    try {
      return (
        rawValue &&
        (mode === "decode"
          ? Hex.parse(rawValue).toString(Utf8)
          : Hex.stringify(Utf8.parse(rawValue)))
      );
    } catch {}
  },

  inputs: {
    rawValue: {
      label: "Raw Value",
      type: "String",
      required: true,
      acceptMultiple: false,
    },
  },
  outputs: {
    processedValue: {
      label: "Processed Value",
      type: "String",
    },
  },
};
