import ButtonBar from "./ButtonBar";
// import Filters from "./Filters";
import StepsList from "./StepsList";

function Toolbar() {
  return (
    <aside className="h-full px-2 py-4">
      <div className="flex flex-col h-full space-y-4 min-w-[250px]">
        {/* <Filters /> */}
        <StepsList />
        <ButtonBar />
      </div>
    </aside>
  );
}

export default Toolbar;
