import { FlowExportObject } from "react-flow-renderer/dist/nocss";
import { useBoardContext } from "../contexts/BoardContext";

export type LibraryEntry = {
  id: string;
  label: string;
  description: string;
  categories?: string[];
  board: FlowExportObject;
};

export const library: LibraryEntry[] = [
  {
    id: "27qk5gGZGt0NfLL-SbHCH",
    label: "Empty Board",
    description: "Clears the board :(",
    board: {
      elements: [],
      position: [0, 0],
      zoom: 1,
    },
  },
  {
    id: "QS1wasuMaOOe3J0Bit9BN",
    label: "Random UUID",
    description: "Creates and outputs a random UUID v4.",
    categories: ["random"],
    board: {
      elements: [
        {
          type: "randomUuid",
          position: { x: 90, y: 285 },
          data: { value: "0f1192f9-7982-4771-8e8c-8afc95408bcf" },
          id: "HQXZJ7lgeNJqFD6U8gv8Y",
        },
        {
          type: "inlinePlainTextOutput",
          position: { x: 420, y: 285 },
          data: {},
          id: "JP6AhU5qK3_phiuKV6k4K",
        },
        {
          source: "HQXZJ7lgeNJqFD6U8gv8Y",
          sourceHandle: "randomUuid",
          target: "JP6AhU5qK3_phiuKV6k4K",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-HQXZJ7lgeNJqFD6U8gv8YrandomUuid-JP6AhU5qK3_phiuKV6k4Ktext",
        },
      ],
      position: [0, 0],
      zoom: 1,
    },
  },
  {
    id: "riQE-Eyjfw1h_apJfJRVk",
    label: "Random nanoid",
    description: "Creates and outputs a random nanoid.",
    categories: ["random"],
    board: {
      elements: [
        {
          type: "randomNanoid",
          position: { x: 90, y: 285 },
          data: { value: "YTUQHM8_8aCJdOVQFPlzB" },
          id: "5O3GP-fAnnXh-1lWj9ycG",
        },
        {
          type: "inlinePlainTextOutput",
          position: { x: 420, y: 285 },
          data: {},
          id: "-9t_RnBqLe_TbrU_zeb7d",
        },
        {
          source: "5O3GP-fAnnXh-1lWj9ycG",
          sourceHandle: "randomNanoid",
          target: "-9t_RnBqLe_TbrU_zeb7d",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-5O3GP-fAnnXh-1lWj9ycGrandomNanoid--9t_RnBqLe_TbrU_zeb7dtext",
        },
      ],
      position: [0, 0],
      zoom: 1,
    },
  },
  {
    id: "eBz80jSyuzXVn2e9v50iv",
    label: "Random String",
    description: "Creates and outputs a random string.",
    categories: ["random"],
    board: {
      elements: [
        {
          type: "randomString",
          position: { x: 90, y: 285 },
          data: {
            chars:
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
            length: 16,
            value: "6hfcHLqup0KsTd6i",
          },
          id: "9DYleE5tqwdg_1jnl8_uF",
        },
        {
          type: "inlinePlainTextOutput",
          position: { x: 420, y: 285 },
          data: {},
          id: "vKoBjix5FBBREltT6UwdJ",
        },
        {
          source: "9DYleE5tqwdg_1jnl8_uF",
          sourceHandle: "randomString",
          target: "vKoBjix5FBBREltT6UwdJ",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-9DYleE5tqwdg_1jnl8_uFrandomString-vKoBjix5FBBREltT6UwdJtext",
        },
      ],
      position: [0, 0],
      zoom: 1,
    },
  },
  {
    id: "Lzw6_40421yEuzPTauPCA",
    label: "Base64 encode",
    description: "Encodes a plain text string to base64.",
    categories: ["decode", "encode"],
    board: {
      elements: [
        {
          type: "inlinePlainTextInput",
          position: { x: 90, y: 285 },
          data: {},
          id: "BXXOaBiO6uQuPaefUTnMK",
        },
        {
          type: "base64",
          position: { x: 420, y: 285 },
          data: { mode: "encode" },
          id: "BfI7Cyzlx5-EsgJlKMCIz",
        },
        {
          type: "inlinePlainTextOutput",
          position: { x: 750, y: 285 },
          data: {},
          id: "K-A6Ohbp9YSVmqTQnR0SV",
        },
        {
          source: "BXXOaBiO6uQuPaefUTnMK",
          sourceHandle: "text",
          target: "BfI7Cyzlx5-EsgJlKMCIz",
          targetHandle: "rawValue",
          type: "default",
          animated: false,
          id: "reactflow__edge-BXXOaBiO6uQuPaefUTnMKtext-BfI7Cyzlx5-EsgJlKMCIzrawValue",
        },
        {
          source: "BfI7Cyzlx5-EsgJlKMCIz",
          sourceHandle: "processedValue",
          target: "K-A6Ohbp9YSVmqTQnR0SV",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-BfI7Cyzlx5-EsgJlKMCIzprocessedValue-K-A6Ohbp9YSVmqTQnR0SVtext",
        },
      ],
      position: [0, 0],
      zoom: 1,
    },
  },
  {
    id: "ARSBMCRObu2WgdNT_dbZc",
    label: "Base64 decode",
    description: "Decodes a base64 string to plain text.",
    categories: ["decode", "encode"],
    board: {
      elements: [
        {
          type: "base64",
          position: { x: 420, y: 285 },
          data: { mode: "decode" },
          id: "OHj9SHkjP6tjF_knh1vAf",
        },
        {
          type: "inlinePlainTextInput",
          position: { x: 90, y: 285 },
          data: {},
          id: "XRbg3Iz4-gg0ywfctsYzH",
        },
        {
          type: "inlinePlainTextOutput",
          position: { x: 750, y: 285 },
          data: {},
          id: "Gc3aQf34Iodi7T-A0sNYq",
        },
        {
          source: "XRbg3Iz4-gg0ywfctsYzH",
          sourceHandle: "text",
          target: "OHj9SHkjP6tjF_knh1vAf",
          targetHandle: "rawValue",
          type: "default",
          animated: false,
          id: "reactflow__edge-XRbg3Iz4-gg0ywfctsYzHtext-OHj9SHkjP6tjF_knh1vAfrawValue",
        },
        {
          source: "OHj9SHkjP6tjF_knh1vAf",
          sourceHandle: "processedValue",
          target: "Gc3aQf34Iodi7T-A0sNYq",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-OHj9SHkjP6tjF_knh1vAfprocessedValue-Gc3aQf34Iodi7T-A0sNYqtext",
        },
      ],
      position: [0, 0],
      zoom: 1,
    },
  },
  {
    id: "CJMNl8AdGwzR7yEOQFZOh",
    label: "Hash MD5",
    description: "Hashes a plain text string as MD5.",
    categories: ["encode"],
    board: {
      elements: [
        {
          type: "inlinePlainTextInput",
          position: { x: 90, y: 285 },
          data: {},
          id: "ATg-YNh3UUJ8BSp38eM8u",
        },
        {
          type: "hash",
          position: { x: 420, y: 285 },
          data: { algo: "md5" },
          id: "x8oEnYfMyNMno9Tnau8zq",
        },
        {
          type: "inlinePlainTextOutput",
          position: { x: 750, y: 285 },
          data: {},
          id: "dQTmPEaQqvFJ74QZ6EoGg",
        },
        {
          source: "ATg-YNh3UUJ8BSp38eM8u",
          sourceHandle: "text",
          target: "x8oEnYfMyNMno9Tnau8zq",
          targetHandle: "rawValue",
          type: "default",
          animated: false,
          id: "reactflow__edge-ATg-YNh3UUJ8BSp38eM8utext-x8oEnYfMyNMno9Tnau8zqrawValue",
        },
        {
          source: "x8oEnYfMyNMno9Tnau8zq",
          sourceHandle: "hashedValue",
          target: "dQTmPEaQqvFJ74QZ6EoGg",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-x8oEnYfMyNMno9Tnau8zqhashedValue-dQTmPEaQqvFJ74QZ6EoGgtext",
        },
      ],
      position: [0, 0],
      zoom: 1,
    },
  },
  {
    id: "LYlNXwvGaWCKbasbTvBnc",
    label: "Hash MD5 with Salt",
    description:
      "Hashes a plain text string as MD5 with a random UUID as salt. This is not secure!",
    categories: ["encode"],
    board: {
      elements: [
        {
          type: "hash",
          position: { x: 750, y: 285 },
          data: { algo: "md5" },
          id: "x8oEnYfMyNMno9Tnau8zq",
        },
        {
          id: "Yaej3pNNoY6l14XKYe_Ig",
          type: "randomUuid",
          position: { x: 90, y: 525 },
          data: { value: "e1907e61-4a7b-45a4-8228-5a07755fb93f" },
        },
        {
          id: "bxI2-9cbzcKUo4YKDyPvb",
          type: "joinInputsFilter",
          position: { x: 420, y: 285 },
          data: { separator: "." },
        },
        {
          id: "5_dN68lHfyWHOI0LSvExX",
          type: "inlinePlainTextOutput",
          position: { x: 750, y: 525 },
          data: {},
        },
        {
          id: "jHjdHS3-rL9wSiz-FE4fc",
          type: "inlinePlainTextInput",
          position: { x: 90, y: 285 },
          data: { value: "" },
        },
        {
          id: "HC6Bx0tNwzdUqT1CpAUNZ",
          type: "inlinePlainTextOutput",
          position: { x: 1080, y: 285 },
          data: {},
        },
        {
          source: "bxI2-9cbzcKUo4YKDyPvb",
          sourceHandle: "hashedValue",
          target: "x8oEnYfMyNMno9Tnau8zq",
          targetHandle: "rawValue",
          type: "default",
          animated: false,
          id: "reactflow__edge-bxI2-9cbzcKUo4YKDyPvbhashedValue-x8oEnYfMyNMno9Tnau8zqrawValue",
        },
        {
          source: "bxI2-9cbzcKUo4YKDyPvb",
          sourceHandle: "hashedValue",
          target: "5_dN68lHfyWHOI0LSvExX",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-bxI2-9cbzcKUo4YKDyPvbhashedValue-5_dN68lHfyWHOI0LSvExXtext",
        },
        {
          source: "jHjdHS3-rL9wSiz-FE4fc",
          sourceHandle: "text",
          target: "bxI2-9cbzcKUo4YKDyPvb",
          targetHandle: "inputValues",
          type: "default",
          animated: false,
          id: "reactflow__edge-jHjdHS3-rL9wSiz-FE4fctext-bxI2-9cbzcKUo4YKDyPvbinputValues",
        },
        {
          source: "Yaej3pNNoY6l14XKYe_Ig",
          sourceHandle: "randomUuid",
          target: "bxI2-9cbzcKUo4YKDyPvb",
          targetHandle: "inputValues",
          type: "default",
          animated: false,
          id: "reactflow__edge-Yaej3pNNoY6l14XKYe_IgrandomUuid-bxI2-9cbzcKUo4YKDyPvbinputValues",
        },
        {
          source: "x8oEnYfMyNMno9Tnau8zq",
          sourceHandle: "hashedValue",
          target: "HC6Bx0tNwzdUqT1CpAUNZ",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-x8oEnYfMyNMno9Tnau8zqhashedValue-HC6Bx0tNwzdUqT1CpAUNZtext",
        },
      ],
      position: [0, 0],
      zoom: 1,
    },
  },
  {
    id: "YTUQHM8_8aCJdOVQFPlzB",
    label: "To Lower Case",
    description: "Converts the given input to lower case.",
    categories: ["encode"],
    board: {
      elements: [
        {
          type: "changeCaseFilter",
          position: { x: 420, y: 285 },
          data: { mode: "lower" },
          id: "MuARV26y6udVz7_jKxd9x",
        },
        {
          type: "inlinePlainTextInput",
          position: { x: 90, y: 285 },
          data: {},
          id: "OzXhXhN3PnoMdKcC8HDyw",
        },
        {
          type: "inlinePlainTextOutput",
          position: { x: 750, y: 285 },
          data: {},
          id: "Inrr8xWSpAVa4KZXdpcjM",
        },
        {
          source: "OzXhXhN3PnoMdKcC8HDyw",
          sourceHandle: "text",
          target: "MuARV26y6udVz7_jKxd9x",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-OzXhXhN3PnoMdKcC8HDywtext-MuARV26y6udVz7_jKxd9xtext",
        },
        {
          source: "MuARV26y6udVz7_jKxd9x",
          sourceHandle: "processedText",
          target: "Inrr8xWSpAVa4KZXdpcjM",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-MuARV26y6udVz7_jKxd9xprocessedText-Inrr8xWSpAVa4KZXdpcjMtext",
        },
      ],
      position: [0, 0],
      zoom: 1,
    },
  },
  {
    id: "CSP6fN-NmgFTPCqEzTlzM",
    label: "To Upper Case",
    description: "Converts the given input to UPPER case.",
    categories: ["encode"],
    board: {
      elements: [
        {
          type: "inlinePlainTextInput",
          position: { x: 90, y: 285 },
          data: { value: "" },
          id: "m-H3gTdXGHI43VxbKvEMn",
        },
        {
          type: "inlinePlainTextOutput",
          position: { x: 750, y: 285 },
          data: {},
          id: "xHx59X4pGRrig3lLwYQun",
        },
        {
          type: "changeCaseFilter",
          position: { x: 420, y: 285 },
          data: { mode: "upper" },
          id: "qQFk8X6JaIriTtpAL8oxh",
        },
        {
          source: "m-H3gTdXGHI43VxbKvEMn",
          sourceHandle: "text",
          target: "qQFk8X6JaIriTtpAL8oxh",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-m-H3gTdXGHI43VxbKvEMntext-qQFk8X6JaIriTtpAL8oxhtext",
        },
        {
          source: "qQFk8X6JaIriTtpAL8oxh",
          sourceHandle: "processedText",
          target: "xHx59X4pGRrig3lLwYQun",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-qQFk8X6JaIriTtpAL8oxhprocessedText-xHx59X4pGRrig3lLwYQuntext",
        },
      ],
      position: [0, 0],
      zoom: 1,
    },
  },
  {
    id: "BNuXtqYuw5jDrxLtR2BMA",
    label: "Shuffle List",
    description: "Shuffles a list (one entry per line).",
    categories: ["shuffle"],
    board: {
      elements: [
        {
          id: "oEej8MHzKdd88VCMFFe-n",
          type: "plainTextInput",
          position: { x: 90, y: 285 },
          data: { value: "One\nEntry\nPer\nLine" },
        },
        {
          id: "5ghBqyTdbo1RPUlyyQuLu",
          type: "splitFilter",
          position: { x: 405, y: 285 },
          data: { separator: "\\n" },
        },
        {
          id: "MW0i0EZFns1zn0HWLp4j2",
          type: "shuffleArrayFilter",
          position: { x: 720, y: 285 },
          data: {},
        },
        {
          id: "63pOsASaYcjCAlf09JQoX",
          type: "plainTextOutput",
          position: { x: 1350, y: 285 },
          data: {},
        },
        {
          id: "fP07IpprMDPYVi4tDedTf",
          type: "joinArrayFilter",
          position: { x: 1035, y: 285 },
          data: { separator: "\\n" },
        },
        {
          source: "oEej8MHzKdd88VCMFFe-n",
          sourceHandle: "text",
          target: "5ghBqyTdbo1RPUlyyQuLu",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-oEej8MHzKdd88VCMFFe-ntext-5ghBqyTdbo1RPUlyyQuLutext",
        },
        {
          source: "5ghBqyTdbo1RPUlyyQuLu",
          sourceHandle: "hashedValue",
          target: "MW0i0EZFns1zn0HWLp4j2",
          targetHandle: "array",
          type: "default",
          animated: false,
          id: "reactflow__edge-5ghBqyTdbo1RPUlyyQuLuhashedValue-MW0i0EZFns1zn0HWLp4j2array",
        },
        {
          source: "MW0i0EZFns1zn0HWLp4j2",
          sourceHandle: "hashedValue",
          target: "fP07IpprMDPYVi4tDedTf",
          targetHandle: "inputValues",
          type: "default",
          animated: false,
          id: "reactflow__edge-MW0i0EZFns1zn0HWLp4j2hashedValue-fP07IpprMDPYVi4tDedTfinputValues",
        },
        {
          source: "fP07IpprMDPYVi4tDedTf",
          sourceHandle: "hashedValue",
          target: "63pOsASaYcjCAlf09JQoX",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-fP07IpprMDPYVi4tDedTfhashedValue-63pOsASaYcjCAlf09JQoXtext",
        },
      ],
      position: [0, 0],
      zoom: 1,
    },
  },
  {
    id: "nQ8zeGeveNq3We5BmPZW2",
    label: "Reverse String",
    description: "Reverses the input string.",
    categories: ["reverse"],
    board: {
      elements: [
        {
          id: "5ghBqyTdbo1RPUlyyQuLu",
          type: "splitFilter",
          position: { x: 405, y: 285 },
          data: { separator: "" },
        },
        {
          id: "f9_voq1lkwJBNdgigVG28",
          type: "inlinePlainTextInput",
          position: { x: 90, y: 285 },
          data: { value: "desrever si sihT" },
        },
        {
          id: "sXghVLDTdYXn4PUy7ukPa",
          type: "inlinePlainTextOutput",
          position: { x: 1350, y: 285 },
          data: {},
        },
        {
          id: "YQ6QyZOU3fL6GgeX6lbSB",
          type: "joinArrayFilter",
          position: { x: 1035, y: 285 },
          data: { separator: "" },
        },
        {
          id: "ag2tnE6IdCw91GOLBtsRU",
          type: "reverseArrayFilter",
          position: { x: 720, y: 285 },
          data: {},
        },
        {
          source: "YQ6QyZOU3fL6GgeX6lbSB",
          sourceHandle: "joinedString",
          target: "sXghVLDTdYXn4PUy7ukPa",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-YQ6QyZOU3fL6GgeX6lbSBjoinedString-sXghVLDTdYXn4PUy7ukPatext",
        },
        {
          source: "5ghBqyTdbo1RPUlyyQuLu",
          sourceHandle: "hashedValue",
          target: "ag2tnE6IdCw91GOLBtsRU",
          targetHandle: "array",
          type: "default",
          animated: false,
          id: "reactflow__edge-5ghBqyTdbo1RPUlyyQuLuhashedValue-ag2tnE6IdCw91GOLBtsRUarray",
        },
        {
          source: "ag2tnE6IdCw91GOLBtsRU",
          sourceHandle: "hashedValue",
          target: "YQ6QyZOU3fL6GgeX6lbSB",
          targetHandle: "inputValues",
          type: "default",
          animated: false,
          id: "reactflow__edge-ag2tnE6IdCw91GOLBtsRUhashedValue-YQ6QyZOU3fL6GgeX6lbSBinputValues",
        },
        {
          source: "f9_voq1lkwJBNdgigVG28",
          sourceHandle: "text",
          target: "5ghBqyTdbo1RPUlyyQuLu",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-f9_voq1lkwJBNdgigVG28text-5ghBqyTdbo1RPUlyyQuLutext",
        },
      ],
      position: [0, 0],
      zoom: 1,
    },
  },
  {
    id: "gtYcsEaxl2U_80sFldIQ4",
    label: "URL Encode",
    description: "URL encodes the input string.",
    categories: ["url", "encode"],
    board: {
      elements: [
        {
          id: "Wv3HzFiyeFfXsckboOQd4",
          type: "plainTextInput",
          position: { x: 90, y: 285 },
          data: { value: "" },
        },
        {
          id: "2jOPBTRtUIGDya6-ndwbd",
          type: "url",
          position: { x: 420, y: 270 },
          data: { mode: "encode" },
        },
        {
          id: "m6nZGMMWp0DTw3MvTu5QZ",
          type: "plainTextOutput",
          position: { x: 750, y: 285 },
          data: {},
        },
        {
          source: "Wv3HzFiyeFfXsckboOQd4",
          sourceHandle: "text",
          target: "2jOPBTRtUIGDya6-ndwbd",
          targetHandle: "rawValue",
          type: "default",
          animated: false,
          id: "reactflow__edge-Wv3HzFiyeFfXsckboOQd4text-2jOPBTRtUIGDya6-ndwbdrawValue",
        },
        {
          source: "2jOPBTRtUIGDya6-ndwbd",
          sourceHandle: "processedValue",
          target: "m6nZGMMWp0DTw3MvTu5QZ",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-2jOPBTRtUIGDya6-ndwbdprocessedValue-m6nZGMMWp0DTw3MvTu5QZtext",
        },
      ],
      position: [-73.81818181818176, -171.95959595959596],
      zoom: 1.6404040404040403,
    },
  },
  {
    id: "s6LgZcJlQp6WD46_Y3oyr",
    label: "URL Decode",
    description: "URL decodes the input string.",
    categories: ["url", "encode"],
    board: {
      elements: [
        {
          id: "-peEEWgnTC8Q8XonsuOtO",
          type: "plainTextInput",
          position: { x: 90, y: 285 },
          data: { value: "" },
        },
        {
          id: "ucJts8S5-sLdZYNel95Ci",
          type: "url",
          position: { x: 420, y: 300 },
          data: { mode: "decode" },
        },
        {
          id: "oHscET-cDbJKwUgFL4Rah",
          type: "plainTextOutput",
          position: { x: 750, y: 285 },
          data: {},
        },
        {
          source: "ucJts8S5-sLdZYNel95Ci",
          sourceHandle: "processedValue",
          target: "oHscET-cDbJKwUgFL4Rah",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-ucJts8S5-sLdZYNel95CiprocessedValue-oHscET-cDbJKwUgFL4Rahtext",
        },
        {
          source: "-peEEWgnTC8Q8XonsuOtO",
          sourceHandle: "text",
          target: "ucJts8S5-sLdZYNel95Ci",
          targetHandle: "rawValue",
          type: "default",
          animated: false,
          id: "reactflow__edge--peEEWgnTC8Q8XonsuOtOtext-ucJts8S5-sLdZYNel95CirawValue",
        },
      ],
      position: [-73.81818181818176, -317.85353535353534],
      zoom: 1.6404040404040403,
    },
  },
  {
    id: "KhyUxutj38__UTq5fnXZ0",
    label: "HTML Encode",
    description: "HTML encodes the input string.",
    categories: ["html", "encode"],
    board: {
      elements: [
        {
          id: "k_BaeN1pFJl9-Hm6bU5Cl",
          type: "plainTextInput",
          position: { x: 90, y: 285 },
          data: { value: "" },
        },
        {
          id: "ZGA-KEQdVdsnf4-gmj_sX",
          type: "html",
          position: { x: 420, y: 270 },
          data: { mode: "encode" },
        },
        {
          id: "pzpT6rLtgW_wvJqgacZZv",
          type: "plainTextOutput",
          position: { x: 750, y: 285 },
          data: {},
        },
        {
          source: "k_BaeN1pFJl9-Hm6bU5Cl",
          sourceHandle: "text",
          target: "ZGA-KEQdVdsnf4-gmj_sX",
          targetHandle: "rawValue",
          type: "default",
          animated: false,
          id: "reactflow__edge-k_BaeN1pFJl9-Hm6bU5Cltext-ZGA-KEQdVdsnf4-gmj_sXrawValue",
        },
        {
          source: "ZGA-KEQdVdsnf4-gmj_sX",
          sourceHandle: "processedValue",
          target: "pzpT6rLtgW_wvJqgacZZv",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-ZGA-KEQdVdsnf4-gmj_sXprocessedValue-pzpT6rLtgW_wvJqgacZZvtext",
        },
      ],
      position: [-73.81818181818176, -317.85353535353534],
      zoom: 1.6404040404040403,
    },
  },
  {
    id: "iSedPV1pfi4ULgWlsPITe",
    label: "HTML Decode",
    description: "HTML decodes the input string.",
    categories: ["html", "encode"],
    board: {
      elements: [
        {
          id: "ueATPLh5_0RxLXagBfBMC",
          type: "plainTextOutput",
          position: { x: 750, y: 285 },
          data: {},
        },
        {
          id: "gX8kYrDKEGsixabTG1vGG",
          type: "html",
          position: { x: 420, y: 270 },
          data: { mode: "decode" },
        },
        {
          id: "w6gE67AGUgLFy0BaoF0-T",
          type: "plainTextInput",
          position: { x: 90, y: 285 },
          data: { value: "" },
        },
        {
          source: "w6gE67AGUgLFy0BaoF0-T",
          sourceHandle: "text",
          target: "gX8kYrDKEGsixabTG1vGG",
          targetHandle: "rawValue",
          type: "default",
          animated: false,
          id: "reactflow__edge-w6gE67AGUgLFy0BaoF0-Ttext-gX8kYrDKEGsixabTG1vGGrawValue",
        },
        {
          source: "gX8kYrDKEGsixabTG1vGG",
          sourceHandle: "processedValue",
          target: "ueATPLh5_0RxLXagBfBMC",
          targetHandle: "text",
          type: "default",
          animated: false,
          id: "reactflow__edge-gX8kYrDKEGsixabTG1vGGprocessedValue-ueATPLh5_0RxLXagBfBMCtext",
        },
      ],
      position: [-73.81818181818176, -317.85353535353534],
      zoom: 1.6404040404040403,
    },
  },
];

type LibraryHook = {
  getLibrary(): LibraryEntry[];
  loadLibraryEntryIntoBoard(id: string): Promise<LibraryEntry>;
};

export function useLibrary(): LibraryHook {
  const { loadBoard, fitView } = useBoardContext();
  function getLibrary(): LibraryEntry[] {
    return library;
  }

  function loadLibraryEntryIntoBoard(id: string): Promise<LibraryEntry> {
    return new Promise<LibraryEntry>((resolve, reject) => {
      const libraryEntry = library.find((entry) => entry.id === id);
      if (!libraryEntry) {
        return reject();
      }

      loadBoard(libraryEntry.board);

      setTimeout(() => fitView(), 25);

      return resolve(libraryEntry);
    });
  }

  return {
    getLibrary,
    loadLibraryEntryIntoBoard,
  };
}
