import { Popover, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren, ReactElement, ReactNode } from "react";
import Button from "./Button";
import ApplyIcon from "../icons/ApplyIcon";

type SettingsPopupProps = PropsWithChildren<{
  buttons?: ReactNode;
  openButton?: ReactElement;
  onSubmit: () => void;
}>;

function SettingsPopup({
  buttons,
  openButton,
  onSubmit,
  children,
  ...props
}: SettingsPopupProps) {
  return (
    <Popover className="relative" {...props}>
      {({ close }) => (
        <>
          {openButton ? (
            openButton
          ) : (
            <Popover.Button className="p-1 transition-colors duration-300 rounded-full text-neutral-400 hover:text-neutral-600 hover:bg-neutral-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="w-4 h-4"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </Popover.Button>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-20 w-screen max-w-xs px-3 py-2 -translate-x-1/2 bg-white rounded-md shadow-md cursor-default nodrag nowheel left-1/2">
              <div>{children}</div>
              <div className="flex justify-end px-3 py-2 mt-4 -mx-3 -mb-2 border-t-2 border-neutral-100 rounded-b-md">
                {buttons ? (
                  buttons
                ) : (
                  <Button
                    onClick={() => {
                      onSubmit();
                      close();
                    }}
                    icon={<ApplyIcon />}
                  >
                    Apply
                  </Button>
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default SettingsPopup;
