import { Link } from "react-router-dom";
import { useBoardContext } from "../../contexts/BoardContext";
import Button from "../ui/Button";

function ButtonBar() {
  const { reactFlowInstance } = useBoardContext();

  return (
    <div className="flex items-center justify-start gap-2 px-3 py-3 -mx-2 !-mb-4 border-t-2 border-neutral-100 ">
      <Button
        onClick={() =>
          console.log(JSON.stringify(reactFlowInstance!.toObject()))
        }
        icon={
          <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
            <g id="color">
              <polygon
                fill="#9B9B9A"
                stroke="none"
                points="56.5,57.0387 68.5,57.0387 68.5,15.9286 4.5,15.9286 4.5,57.0387 16.4998,57.0387"
              />
              <polyline
                fill="#9B9B9A"
                stroke="none"
                points="16.4998,56 4.5,56 4.5,15.9286 68.5,15.9286 68.5,56 56.5,56"
              />
              <rect
                x="5.3926"
                y="15.9286"
                width="7.1411"
                height="40.0714"
                fill="#D0CFCE"
                stroke="none"
              />
              <rect
                x="60.1359"
                y="15.9286"
                width="7.9178"
                height="40.0714"
                fill="#D0CFCE"
                stroke="none"
              />
              <polyline
                fill="#D0CFCE"
                stroke="none"
                points="15.5006,15.4123 15.5006,4.9923 57.4994,4.9923 57.4994,15.9286"
              />
              <polyline
                fill="#D0CFCE"
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                points="16.5,12.5029 16.5,4.9923 56.5,4.9923 56.5,12.8751"
              />
              <polyline
                fill="#3F3F3F"
                stroke="none"
                points="52.5398,44.4334 52.5398,68 20.4602,68 20.4602,44.4334"
              />
              <polyline
                fill="#3F3F3F"
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                points="51.5857,50.5871 51.5857,68 21.4143,68 21.4143,50.5871"
              />
              <line
                x1="61.1454"
                x2="61.1454"
                y1="20.3352"
                y2="52.2851"
                fill="#FFFFFF"
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
              <polyline
                fill="#FFFFFF"
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                points="46.7833,44.4525 46.7833,63.7559 26.2167,63.7559 26.2167,44.4525"
              />
              <line
                x1="11.5"
                x2="11.5"
                y1="20.3352"
                y2="52.2851"
                fill="#FFFFFF"
                stroke="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
            </g>
            <g id="hair" />
            <g id="skin" />
            <g id="skin-shadow" />
            <g id="line">
              <polyline
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                points="16.5,12.5029 16.5,4.9923 56.5,4.9923 56.5,12.8751"
              />
              <polyline
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                points="51.5857,50.5871 51.5857,68 21.4143,68 21.4143,50.5871"
              />
              <line
                x1="61.1454"
                x2="61.1454"
                y1="20.3352"
                y2="52.2851"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
              <polyline
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                points="46.7833,44.4525 46.7833,63.7559 26.2167,63.7559 26.2167,44.4525"
              />
              <line
                x1="29.5276"
                x2="43.4724"
                y1="48.3784"
                y2="48.3784"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
              <line
                x1="29.5276"
                x2="43.4724"
                y1="53.2052"
                y2="53.2052"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
              <line
                x1="29.5276"
                x2="43.4724"
                y1="58.5044"
                y2="58.5044"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
              <line
                x1="11.5"
                x2="11.5"
                y1="20.3352"
                y2="52.2851"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
              <path
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M16.5,52.2851v-3.1353c0-2.6048,2.1116-4.7164,4.7164-4.7164h30.5672c2.6048,0,4.7164,2.1116,4.7164,4.7164v3.1353"
              />
              <polyline
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
                points="56.5,56 68.5,56 68.5,15.9286 4.5,15.9286 4.5,56 16.4998,56"
              />
            </g>
          </svg>
        }
      >
        Serialize
      </Button>
      <Link to="/library">
        <Button
          // onClick={() => setModalOpened(true)}
          icon={
            <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
              <g id="color">
                <path
                  fill="#FCEA2B"
                  stroke="none"
                  d="M60.1001,57.0799c0.5632,0,1.0198-0.4576,1.0198-1.0222V12.0344c0-0.5645-0.4566-1.0222-1.0198-1.0222 h-43.922c-0.5632,0-1.0198,0.4576-1.0198,1.0222V39.163l36.0608,17.917H60.1001z"
                />
                <path
                  fill="#FCEA2B"
                  stroke="none"
                  d="M60.1214,56.0122c0.5514,0,0.9985-0.447,0.9985-0.9985v-43.003c0-0.5514-0.447-0.9985-0.9985-0.9985 h-43.003c-0.5514,0-0.9985,0.447-0.9985,0.9985v44.0015H60.1214z"
                />
                <path
                  fill="#FCEA2B"
                  stroke="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M16.1199,12.0107c0-0.5514,0.447-0.9985,0.9985-0.9985h43.003c0.5514,0,0.9985,0.447,0.9985,0.9985v43.003 c0,0.5514-0.447,0.9985-0.9985,0.9985"
                />
                <path
                  fill="#F1B31C"
                  stroke="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M55.1336,61h-43.003c-0.5514,0-0.9985-0.447-0.9985-0.9985v-5.8828V25.6668v-3.2316v-5.4367c0-0.5514,0.447-0.9985,0.9985-0.9985 h43.003c0.5514,0,0.9985,0.447,0.9985,0.9985v43.003C56.1321,60.553,55.685,61,55.1336,61z"
                />
              </g>
              <g id="hair" />
              <g id="skin" />
              <g id="skin-shadow" />
              <g id="line">
                <path
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M16.1199,12.0107c0-0.5514,0.447-0.9985,0.9985-0.9985h43.003c0.5514,0,0.9985,0.447,0.9985,0.9985v43.003 c0,0.5514-0.447,0.9985-0.9985,0.9985"
                />
                <path
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M55.1336,61h-43.003c-0.5514,0-0.9985-0.447-0.9985-0.9985v-5.8828V25.6668v-3.2316v-5.4367c0-0.5514,0.447-0.9985,0.9985-0.9985 h43.003c0.5514,0,0.9985,0.447,0.9985,0.9985v43.003C56.1321,60.553,55.685,61,55.1336,61z"
                />
                <line
                  x1="7"
                  x2="15.7116"
                  y1="23.4114"
                  y2="23.4114"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
                <line
                  x1="7"
                  x2="15.7116"
                  y1="29.4469"
                  y2="29.4469"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
                <line
                  x1="7"
                  x2="15.7116"
                  y1="35.4823"
                  y2="35.4823"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
                <line
                  x1="15.7116"
                  x2="7"
                  y1="41.5177"
                  y2="41.5177"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
                <line
                  x1="7"
                  x2="15.7116"
                  y1="47.5532"
                  y2="47.5532"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
                <line
                  x1="7"
                  x2="15.7116"
                  y1="53.5886"
                  y2="53.5886"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
              </g>
            </svg>
          }
        >
          Library
        </Button>
      </Link>
    </div>
  );
}

export default ButtonBar;
