import { Component, FunctionComponent } from "react";
import {
  Connection,
  Elements,
  isNode,
  Node,
  NodeProps,
} from "react-flow-renderer/nocss";

import { definition as plainTextInputDefinition } from "./components/inputs/PlainTextInput";
import { definition as inlinePlainTextInputDefinition } from "./components/inputs/InlinePlainTextInput";
import { definition as randomStringDefinition } from "./components/inputs/RandomStringInput";
import { definition as randomUuidDefinition } from "./components/inputs/RandomUuidInput";
import { definition as randomNanoidDefinition } from "./components/inputs/RandomNanoidInput";

import { definition as base64FilterDefinition } from "./components/filters/Base64Filter";
import { definition as urlFilterDefinition } from "./components/filters/UrlFilter";
import { definition as htmlFilterDefinition } from "./components/filters/HtmlFilter";
import { definition as hexFilterDefinition } from "./components/filters/HexFilter";
import { definition as hashFilterDefinition } from "./components/filters/HashFilter";
import { definition as changeCaseFilterDefinition } from "./components/filters/ChangeCaseFilter";
import { definition as joinInputsFilterDefinition } from "./components/filters/JoinInputsFilter";
import { definition as splitFilterDefinition } from "./components/filters/SplitFilter";
import { definition as shuffleArrayFilterDefinition } from "./components/filters/ShuffleArrayFilter";
import { definition as reverseArrayFilterDefinition } from "./components/filters/ReverseArrayFilter";
import { definition as joinArrayFilterDefinition } from "./components/filters/JoinArrayFilter";

import { definition as plainTextOutputDefinition } from "./components/outputs/PlainTextOutput";
import { definition as inlinePlainTextOutputDefinition } from "./components/outputs/InlinePlainTextOutput";
import { getEdges } from "./utils/tree";

export const STEP_DEFINITIONS: { [id: string]: StepDefinition } = {
  plainTextInput: plainTextInputDefinition,
  inlinePlainTextInput: inlinePlainTextInputDefinition,
  randomString: randomStringDefinition,
  randomUuid: randomUuidDefinition,
  randomNanoid: randomNanoidDefinition,

  base64: base64FilterDefinition,
  url: urlFilterDefinition,
  html: htmlFilterDefinition,
  hex: hexFilterDefinition,
  hash: hashFilterDefinition,
  changeCaseFilter: changeCaseFilterDefinition,
  splitFilter: splitFilterDefinition,
  shuffleArrayFilter: shuffleArrayFilterDefinition,
  reverseArrayFilter: reverseArrayFilterDefinition,
  joinArrayFilter: joinArrayFilterDefinition,

  joinInputsFilter: joinInputsFilterDefinition,

  plainTextOutput: plainTextOutputDefinition,
  inlinePlainTextOutput: inlinePlainTextOutputDefinition,
};

export function getStepDefinition(type?: string): StepDefinition | null {
  return type ? STEP_DEFINITIONS[type] : null;
}

export function getStepDefinitions(): (StepDefinition & WithId)[] {
  return Object.keys(STEP_DEFINITIONS).map((key) => ({
    id: key,
    ...STEP_DEFINITIONS[key],
  }));
}

export type ParameterType = "String" | "StringArray";

export type ParameterValues = { [stepId: string]: any };
export type InputValues = any;

export type WithId = { id: string };
export type ParamDefinition = {
  label: string;
  type: ParameterType;
};

export type InputDefinition = ParamDefinition & {
  acceptMultiple?: boolean;
  required?: boolean;
};
export type OutputDefinition = ParamDefinition;

export type Inputs = {
  [name: string]: InputDefinition;
};
export type Outputs = {
  [name: string]: OutputDefinition;
};
export type StepData = any;

export type StepDefinition<TData = StepData, TInputs = InputValues> = {
  label: string;
  description: string;
  group: StepGroup;

  component: Component<NodeProps> | FunctionComponent<NodeProps>;

  inputs: Inputs;
  outputs: Outputs;

  pipe: (inputs: TInputs, data: TData) => any; // TODO
  init?: (data: TData) => void;
  refresh?: (data: TData) => void;
};

export const canBeConnected = (connection: Connection, elements: Elements) => {
  const source = elements.find(
    (node) => node.id === connection.source && isNode(node)
  ) as Node;
  const target = elements.find(
    (node) => node.id === connection.target && isNode(node)
  ) as Node;

  if (!source || !target) {
    return false;
  }

  const sourceDefinition = getStepDefinition(source.type);
  const sourceOutput = sourceDefinition?.outputs[connection.sourceHandle!];

  const targetDefinition = getStepDefinition(target.type);
  const targetInput = targetDefinition?.inputs[connection.targetHandle!];

  const edges = getEdges(target, elements);
  const handleIsFull =
    edges.findIndex((edge) => edge.targetHandle === connection.targetHandle) !==
    -1;

  if (handleIsFull && !targetInput?.acceptMultiple) {
    return false;
  }

  return sourceOutput?.type === targetInput?.type;
};

export function getInputs(definition: {
  inputs: Inputs;
}): (InputDefinition & WithId)[] {
  return Object.keys(definition.inputs).map((key) => ({
    id: key,
    ...definition.inputs[key],
  }));
}

export function getOutputs(definition: {
  outputs: Outputs;
}): (OutputDefinition & WithId)[] {
  return Object.keys(definition.outputs).map((key) => ({
    id: key,
    ...definition.outputs[key],
  }));
}

export type StepGroupDefinition = {
  label: string;
};

export type StepGroup = "input" | "filter" | "output";

type StepGroups = {
  [id in StepGroup]: StepGroupDefinition;
};

const STEP_GROUPS: StepGroups = {
  input: {
    label: "Inputs",
  },
  filter: {
    label: "Filters",
  },
  output: {
    label: "Outputs",
  },
};

export function getStepGroupDefinition(kind: StepGroup): StepGroupDefinition {
  return STEP_GROUPS[kind];
}

export function getStepGroupDefinitions(): (StepGroupDefinition & {
  id: StepGroup;
})[] {
  return Object.keys(STEP_GROUPS)
    .map((id) => id as StepGroup)
    .map((id) => ({
      id: id as StepGroup,
      ...STEP_GROUPS[id],
    }));
}

type Group = {
  steps: (StepDefinition & WithId)[];
} & StepGroupDefinition & { id: StepGroup };

export function getGroupedStepDefinitions(): Group[] {
  const steps = getStepDefinitions();

  return getStepGroupDefinitions().map((group) => ({
    ...group,
    steps: steps.filter((step) => step.group === group.id),
  }));
}
