import { memo, useCallback, useRef, useState } from "react";
import { useBoardContext } from "../../contexts/BoardContext";
import { nodeDataIsUnchanged } from "../../utils/render";
import { StepDefinition } from "../../stepDefinitions";
import Button from "../ui/Button";
import Modal from "../ui/Modal";
import AbstractNode from "../AbstractNode";
import ApplyIcon from '../icons/ApplyIcon';

const PlainTextInput = memo((props) => {
  const { updateElementData } = useBoardContext();

  const [isModalOpened, setModalOpened] = useState(false);
  const [inputValue, setInputValue] = useState(props.data.value);
  const textareaRef = useRef(null);

  const commit = useCallback(() => {
    updateElementData(props.id, { value: inputValue });
    setModalOpened(false);
  }, [updateElementData, props.id, inputValue]);

  return (
    <>
      <Modal
        title={definition.label}
        opened={isModalOpened}
        setOpened={setModalOpened}
        initialFocus={textareaRef}
        buttons={
          <Button onClick={commit} icon={<ApplyIcon />}>
            Apply
          </Button>
        }
      >
        <label className="block">
          <span className="text-sm font-semibold text-neutral-900">Input</span>
          <textarea
            className="scrollbar input input--input leading-5 min-h-[160px] w-full"
            value={inputValue}
            onChange={({ target }) => setInputValue(target.value)}
            ref={textareaRef}
          />
        </label>
      </Modal>

      <AbstractNode
        buttons={
          <button
            onClick={() => setModalOpened(true)}
            className="p-1 transition-colors duration-300 rounded-full text-neutral-400 hover:text-neutral-600 hover:bg-neutral-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="w-4 h-4"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </button>
        }
        {...props}
      />
    </>
  );
}, nodeDataIsUnchanged);

export default PlainTextInput;

type PlainTextInputData = { value: string };

export const definition: StepDefinition<PlainTextInputData> = {
  label: "Plain Text Input",
  description: "Takes its input from a simple multi-line text field.",
  group: "input",

  component: PlainTextInput,

  init(data) {
    data.value = data.value || "";
  },

  pipe(_, { value }) {
    return value;
  },

  inputs: {},
  outputs: {
    text: {
      label: "Text",
      type: "String",
    },
  },
};
