import classNames from "classnames";
import { createElement, HTMLAttributes, PropsWithChildren } from "react";

type TagProps = PropsWithChildren<
  {
    className?: string;
    as?: keyof JSX.IntrinsicElements;
  } & HTMLAttributes<HTMLElement>
>;

function Tag({ as, className, children, ...props }: TagProps) {
  return createElement(
    as || "div",
    {
      className: classNames(
        "text-xs bg-white border-neutral-50 text-neutral-600 hover:text-neutral-900 px-2 py-0.5 font-semibold rounded-md border hover:border-neutral-200 hover:shadow-sm transition duration-300",
        className
      ),
      ...props,
    },
    children
  );
}

export default Tag;
